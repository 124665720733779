<template>
  <v-container
    fluid
    tag="section"
  >
    <!--  -->
    <v-row justify="center">
      <!--  -->
      <v-col
        cols="12"
        md="8"
      >
        <v-card class="mx-auto">
          <v-card-text class="text--primary">
            <div
              v-if="isLoading"
              class="text-center mt-5 mb-5"
            >
              <v-progress-circular
                indeterminate
                color="primary"
              />
            </div>
            <v-col
              v-for="(item, i) in aktifitas.data"
              :key="i"
              cols="12"
            >
              <v-card class="mt-n5">
                <div class="d-flex flex-no-wrap justify-start align-start">
                  <div class="d-flex flex-column align-center">
                    <v-avatar
                      class="ma-3"
                      size="32"
                    >
                      <v-img
                        src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                      />
                    </v-avatar>
                  </div>

                  <div class="d-flex flex-column align-start flex-grow-1">
                    <h1>
                      <v-card-title
                        class="text--primary ml-n3 btn"
                        to="/"
                        v-text="item.user.name"
                      />
                    </h1>
                    <v-card-text
                      class="text--primary mt-n5 ml-n3"
                      v-text="item.post"
                    />
                  </div>
                  <div class="d-flex align-center align-self-sm-center">
                    <v-tooltip
                      bottom
                      color="red"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="red"
                          :to="'/aktifitas/' + item.user_id + '/show'"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>
                            mdi-eye
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>view</span>
                    </v-tooltip>
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col>
              <v-card class="mt-n5">
                <div class="d-flex flex-no-wrap justify-start align-start">
                  <div class="d-flex flex-column align-center">
                    <v-avatar
                      class="ma-3"
                      size="32"
                    >
                      <v-img
                        src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                      />
                    </v-avatar>
                  </div>

                  <div class="d-flex flex-column align-start flex-grow-1">
                    <v-form
                      style="width: 100%"
                      @submit.prevent="sendActivity"
                    >
                      <v-text-field
                        v-model="post"
                        label="komentar"
                        placeholder="Ketik Disini"
                      />
                    </v-form>
                  </div>

                  <div class="d-flex align-center align-self-sm-center">
                    <v-tooltip
                      right
                      color="blue"
                    >
                      <!--  -->
                      <template v-slot:activator="{ on, attrs }">
                        <!--  -->
                        <v-btn
                          icon
                          color="blue"
                          v-bind="attrs"
                          v-on="on"
                          @click.prevent="sendActivity"
                        >
                          <v-icon>
                            mdi-send
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>send</span>
                    </v-tooltip>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')
  export default {
    data () {
      return {
        aktifitas: {
          data: {},
          meta: {},
        },
        meta_aktifitas: {
          next: null,
        },
        status_items: ['open', 'close'],
        categoryRules: [value => value !== '' || 'Kategori wajib diisi'],
        isEditing: false,
        isLoading: false,
        post: '',
        location: '',
      }
    },
    mounted () {
      this.getAkfitas()
    },
    methods: {
      sendActivity () {
        axios
          .post('/v1/user/activity', {
            post: this.post,
            status: 'publish',
            url: 'htpps://facebook.com',
          })
          .then(response => {
            if (response.data.meta.status) {
              this.$toast.success('Berhasil Di Buat', {
                type: 'success',
                position: 'top-right',
                duration: 3000,
                dismissible: true,
              })
              this.post = ''
            }

            // update
            this.getAkfitas()
          })
      },
      sendComments () {
        axios
          .post('/v1/forum/thread/' + this.$route.params.id + '/comment', {
            post: this.post,
          })
          .then(response => {
            if (response.data.meta.status) {
              const item = response.data.data
              this.aktifitas.push(item)
              this.post = ''
              this.$toast.success('Komentar Terkirim', {
                type: 'success',
                position: 'top-right',
                duration: 3000,
                dismissible: true,
              })
            } else {
              console.log(response.data.meta.message)
            }
          })
      },
      setAktifitas (data) {
        this.aktifitas = data
      },
      getAkfitas () {
        axios.get('/v1/user/activity?entities=user&sort=-id').then(response => {
          this.setAktifitas(response.data)
        })
      },
    },
  }
</script>

<style>
@media screen and (max-width: 1904px) {
  .img-height {
    height: 400px;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .img-height {
    height: 200px;
  }
}
</style>
